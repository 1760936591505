<template>
  <page icon="user-circle" title="Account" :loading="loading">

    <template #tools>
      <div class="tabs is-toggle">
        <ul>
          <router-link exact tag="li" :to="{ name: 'company-overview'}">
            <a>Overview</a>
          </router-link>
          <router-link v-if="$root.hasAbility('manage-barcodes', 'Billow\\Models\\Company')" exact tag="li"
                       :to="{ name: 'company-barcodes'}">
            <a>Barcodes</a>
          </router-link>
          <router-link v-if="$root.hasAbility('edit-company-information', 'Billow\\Models\\Company')" exact tag="li"
                       :to="{ name: 'company-edit'}">
            <a>Edit</a>
          </router-link>
          <router-link v-if="hasNamingConventionsPermission" exact tag="li"
                       :to="{ name: 'company-manage-naming-conventions'}">
            <a>Naming Conventions</a>
          </router-link>
        </ul>
      </div>
    </template>

    <router-view/>
  </page>
</template>

<script>
import {mapGetters} from 'vuex'

export default {

  data: () => ({
    loading: true
  }),

  beforeCreate() {
    this.$store.dispatch('company/load').then(() => {
      this.loading = false
    })
  },

  computed: {
    ...mapGetters('auth', [
      'user'
    ]),
    ...mapGetters('company', [
      'company'
    ]),
    hasNamingConventionsPermission() {
      return this.company.allow_naming_conventions &&
          this.$root.hasAbility('manage-naming-conventions', 'Billow\\Models\\Company') &&
          this.isCompanyPrimaryUser
    },
  }

}
</script>
