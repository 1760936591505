var render = function render(){var _vm=this,_c=_vm._self._c;return _c('loader',_vm._b({},'loader',{ loading: _vm.loading },false),[_c('div',{staticClass:"is-flex justify-between mb-1"},[_c('h3',{staticClass:"is-size-4"},[_vm._v("Edit "+_vm._s(_vm.Naming.CustomField)+" Template")]),_c('div',{staticClass:"buttons has-addons is-rounded is-right"},[_c('router-link',{staticClass:"button is-cool-blue-darker is-rounded",attrs:{"to":{
            name: 'custom-field-templates',
            params: {
              client: this.$route.params.client
            }}}},[_c('icon',{attrs:{"icon":"angle-double-left"}}),_c('span',[_vm._v(_vm._s(_vm.Naming.CustomField)+" Templates")])],1),(_vm.$root.hasAbility('assign-custom-field-template-to-sites', 'App\\Models\\CustomFieldTemplate'))?_c('router-link',{staticClass:"button is-info is-rounded",attrs:{"to":{
            name: 'template-assign-to-sites',
            params: this.$route.params}}},[_c('icon',{attrs:{"icon":"list-alt"}}),_c('span',[_vm._v("Assign Template To "+_vm._s(_vm.Naming.Sites))])],1):_vm._e()],1)]),_c('div',{staticClass:"box"},[_c('div',{staticClass:"is-flex justify-between align-center mb-1"},[_c('h3',{staticClass:"is-size-4 has-text-weight-light"},[_vm._v("Edit Template")])]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-6"},[_c('text-input',{attrs:{"required":""},model:{value:(_vm.customFieldTemplate.name),callback:function ($$v) {_vm.$set(_vm.customFieldTemplate, "name", $$v)},expression:"customFieldTemplate.name"}},[_vm._v(" Edit Template Name ")])],1),_c('div',{staticClass:"column is-6"},[_c('data-selector',{attrs:{"classes":"is-marginless","required":"","value-key":"value","label-key":"name","items":_vm.templateTypes,"prompt-label":"Select template type"},model:{value:(_vm.customFieldTemplate.template_type),callback:function ($$v) {_vm.$set(_vm.customFieldTemplate, "template_type", $$v)},expression:"customFieldTemplate.template_type"}},[_vm._v(" Template Type ")])],1)]),_c('action-button',{staticClass:"is-success",attrs:{"slot":"right","disabled":_vm.templateDataNotCompleted,"working":_vm.storing,"left-icon":"save"},on:{"click":_vm.updateTemplate},slot:"right"},[_vm._v(" Save ")]),_c('hr'),_c('div',{staticClass:"is-flex justify-between align-center mt-1 mb-1"},[_c('h3',{staticClass:"is-size-4 has-text-weight-light"},[_vm._v(_vm._s(_vm.Naming.CustomFields))]),(_vm.canCreate)?_c('router-link',{staticClass:"button is-primary",attrs:{"to":{
              name: 'template-create-custom-field',
              params: _vm.$route.params
          }}},[_c('icon',{attrs:{"icon":"plus-circle"}}),_c('span',[_vm._v("Add New "+_vm._s(_vm.Naming.CustomField))])],1):_vm._e()],1),_c('custom-fields-draggable-box',{attrs:{"customFields":_vm.customFieldTemplate.custom_fields},on:{"refreshParent":_vm.loadTemplate}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }