<template>
  <loader v-bind="{ loading }">
    <page icon="columns" :title="`${company.name} - ${Naming.CustomFields}`">
      <div slot="tools">
        <div class="tabs is-toggle">
          <ul>
            <router-link
                exact tag="li"
                :to="{ name: 'custom-field-templates' }"
                v-if="$root.hasAbility('view-custom-field-templates', 'App\\Models\\CustomFieldTemplate')">
              <a>
                <icon icon="clipboard-list"/>
                Templates
              </a>
            </router-link>
            <router-link
                exact tag="li"
                :to="{ name: 'create-custom-field-template'}"
                v-if="$root.hasAbility('create-custom-field-templates', 'App\\Models\\CustomFieldTemplate')">
              <a>
                <icon icon="plus-circle"/>
                New Template
              </a>
            </router-link>
            <router-link
                exact tag="li"
                :to="{ name: 'deleted-templates'}"
                v-if="$root.hasAbility('view-trashed-custom-field-templates', 'App\\Models\\CustomFieldTemplate')">
              <a>
                <icon icon="trash-restore"/>
                Deleted Templates
              </a>
            </router-link>
          </ul>
        </div>
      </div>
      <router-view/>
    </page>
  </loader>
</template>
<script>
import {mapGetters} from "vuex";

export default {
  name: 'custom-field-templates-overview',

  data: () => ({
    loading: true
  }),

  computed: {
    ...mapGetters('company', ['company'])
  },

  mounted() {
    this.loading = false
  }
}
</script>