<template>
  <loader v-bind="{ loading }">
    <div class="box">
      <div class="is-flex justify-between">
        <h3 class="title is-4">Restore Deleted Templates</h3>
        <router-link
            class="button is-cool-blue-darker is-rounded"
            :to="{
            name: 'custom-field-templates',
          }">
          <icon icon="angle-double-left"/>
          <span>{{ Naming.CustomField }} Templates </span>
        </router-link>
      </div>
      <table class="table is-fullwidth" v-if="customFieldTemplates.length > 0">
        <thead>
        <tr>
          <td>Template Name</td>
          <td>{{ Naming.CustomFields }}</td>
          <td>Deleted At</td>
          <td>Restore</td>
        </tr>
        </thead>
        <tbody>
        <tr
            v-for="customFieldTemplate in customFieldTemplates"
            :key="customFieldTemplate.id">
          <td>{{ customFieldTemplate.name }}</td>
          <td>
            {{ customFieldTemplate.custom_fields_count ? customFieldTemplate.custom_fields_count : "-" }}
          </td>
          <td>{{ customFieldTemplate.deleted_at | date("L LTS") }}</td>
          <td class="is-narrow">
            <action-button
                :disabled="!$root.hasAbility('restore-custom-field-templates', 'App\\Models\\CustomFieldTemplate')"
                @click="restoreDeletedTemplate(customFieldTemplate.id)"
                class="is-info"
                :working="working">
              <icon icon="trash-restore"/>
            </action-button>
          </td>
        </tr>
        </tbody>
      </table>
      <div class="has-text-centered has-text-grey has-background-cool-blue p-2" v-else>
        There are no deleted {{ Naming.CustomField.toLowerCase() }} templates.
      </div>
    </div>
  </loader>
</template>
<script>
import {mapGetters} from "vuex";

export default {
  name: "deleted-templates",

  data: () => ({
    working: false,
    loading: true,
  }),

  computed: {
    ...mapGetters("customField", ["customFieldTemplates"]),
    ...mapGetters("auth", ["user"]),
  },

  created() {
    this.loadDeletedTemplates()
  },

  methods: {
    async loadDeletedTemplates() {
      await this.$store.dispatch("customField/loadDeletedTemplates").then(() => {
        this.loading = false
      });
    },
    async restoreDeletedTemplate(templateId) {
      this.working = true
      await this.$store.dispatch("customField/restoreDeletedTemplate", templateId)
          .then((data) => {
            this.$toast.success(`${data.name} successfully restored.`)
            this.loadDeletedTemplates()
            this.working = false
          })
          .catch(() => {
            this.$toast.error(`Something went wrong, template not restored, please try again.`)
            this.working = false
          });
    },
  },
};
</script>