<template>
<loader v-bind="{ loading }">
  <div>
    <div class="columns">
      <div class="column">
        <h3 class="title is-4 has-text-weight-light has-text-primary">Overall Usage</h3>
      </div>
      <div class="column is-narrow">
        <action-button :working="downloading" @click="downloadUserActivityReport">Download {{ Naming.User }} Activities</action-button>
      </div>
    </div>

    <div class="box pt-2 summary-item-background">
      <div class="is-flex justify-around summary-item-background">
        <summary-item
          icon="address-book" 
          :title="Naming.Clients"
          :value="overview.clients"/>

        <summary-item
          icon="building" 
          :title="Naming.Sites"
          :value="overview.sites"/>

        <summary-item
          icon="users" 
          :title="Naming.Users"
          :value="overview.users"/>        
        
        <summary-item
          icon="coins" 
          :title="Naming.Assets"
          :value="overview.assets"/>        
        
        <summary-item
          icon="hashtag" 
          :title="Naming.Jobs"
          :value="overview.jobs"/>

        <summary-item
          icon="clipboard-list" 
          :title="Naming.Checklists"
          :value="overview.checklists"/>            
        
        <summary-item
          icon="clipboard-check" 
          :title="Naming.Checks"
          :value="overview.checks"/>            
        
        <summary-item 
          icon="hdd" 
          title="Storage" 
          :value="storageSize"/>            
      </div>
    </div>

    <h3 class="title is-4 has-text-weight-light">{{ Naming.Jobs }}</h3>
    <div class="box pt-2 summary-item-background">
      <div class="is-flex justify-around summary-item-background">

        <summary-item
          icon="co`ins"
          :title="`${Naming.Assets.toLowerCase()} per ${Naming.Job.toLowerCase()}`"
          :value="assetsPerJob.toFixed(2)"/> 
        
        <summary-item
          icon="hashtag"
          :title="`${Naming.Jobs} per ${Naming.Site.toLowerCase()}`"
          :value="jobsPerSite.toFixed(2)"/> 
        
        <summary-item 
          icon="user"
          :title="`${Naming.Jobs} per ${Naming.Technician.toLowerCase()}`"
          :value="averageJobsPerTech.toFixed(2)"/> 
      </div>
    </div>  
  </div>
</loader>  
</template>

<script>
import { common as backend } from '@/api'
import download from 'downloadjs'

export default {

  data: () => ({
    loading: true,
    downloading: false,
    overview: {
      clients: 0,
      sites: 0,
      storage: 0,
      jobs: 0,
      checklists: 0,
      checks: 0,
      assets: 0,
      users: 0,
      total_storage: 0
    },
    jobs: {}
  }),

  created() {
    backend.loadPath({
      path: '/api/company/overview'
    }, response => {
      this.overview = response.data.stats || {}
      this.jobs = response.data.job_stats || {}
      this.loading = false
    }, error => {
      this.loading = false
    })
  },

  methods: {
    downloadUserActivityReport() {
      this.downloading = true
      backend.downloadFile('/api/company/user-activities', ({ data }) => {
        this.downloading = false
        download(
          data,
          `user-activities.xlsx`,
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        )
      }, error => {
        this.$whoops()
        this.downloading = false
      })
    }
  },

  computed: {
    totalHours() {
      return this.overview.usage.average_job_length.reduce((carry, hours) => {
        return carry + hours
      }, 0)
    },
    averageHours() {
      return (this.totalHours) ? this.totalHours / this.overview.usage.average_job_length.length : 0
    },
    averageJobsPerTech() {
      return this.overview.jobs ? this.overview.jobs / this.overview.users : 0
    },
    storageSize() {
      return this.$options.filters.filesize(
        this.overview.total_storage
      )
    },
    assetsPerJob() {
      return this.overview.assets ? (this.overview.assets / this.overview.jobs) : 0
    },
    jobsPerSite() {
      return this.overview.jobs ? (this.overview.jobs / this.overview.sites) : 0
    }
  }

}
</script>