<template>
<loader class="box" v-bind="{ loading }">
  <div v-if="!cancellation.id">
    <div class="columns">
      <div class="column">
        <h4 class="is-size-4">Cancel My Account</h4>
        <p>
          We're really sorry to see you go. Please let us know why you are cancelling your account below, before submitting for cancellation.
        </p>
      </div>
    </div> 
    <div class="columns">
      <div class="column">
        <text-area :error="$root.errors.reason" :value="cancellation.reason" @input="updateCancellationReason">Reason for cancelling</text-area>
      </div>
    </div>   
    <div class="columns">
      <div class="column">
        <submit-button @submit="submitCancellation" class="is-danger is-rounded">Submit Cancellation</submit-button>
      </div>
    </div>  
  </div>

  <div v-else>
    <div v-if="cancellation.is_future">
      <h4 class="is-size-4 mb-1">
        <icon icon="clock"/>
        Pending Cancellation
      </h4>

      <p>
        Your account will be cancelled at {{ cancellation.effective_from | date('L LTS') }}. You can stop the cancellation at anytime before this date.
      </p>

      <p>
        If you would like to stop the cancellation of your account click the button below.
      </p>

      <action-button @click="stopCancellation" class="is-success is-large mt-2">Stop Cancellation</action-button>
    </div>
  </div>
</loader>  
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {

  data: () => ({
    loading: true
  }),

  beforeCreate() {
    this.$store.dispatch('company/loadCancellation').then(() => {
      this.loading = false
    })
  },

  methods: {
    ...mapMutations('company', [
      'updateCancellationReason'
    ]),
    async stopCancellation() {
      if(await this.$confirm({
        title: 'Stop Account Cancellation',
        message: 'Your account cancellation will be stopped.'
      })) {
        this.$store.dispatch('company/stopCancellation', this.cancellation).then(() => {
          this.$toast.success('Account reactivated')
        }).catch(error => {})
      }
    },
    async submitCancellation() {
      if(await this.$confirm({
        title: 'Cancel My Account',
        message: 'Your account will be submitted for cancellation'
      })) {
        this.$store.dispatch('company/submitCancellation', this.cancellation).then(() => {
          this.$toast.success('Account cancelled')
        }).catch(error => {})
      }
    }
  },

  computed: {
    ...mapGetters('auth', [
      'user'
    ]),
    ...mapGetters('company', [
      'cancellation'
    ])
  }

}
</script>
