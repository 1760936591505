<template>
  <div class="columns" v-if="loaded">
    <div class="column">
      <form class="box">
        <div class="columns">
          <div class="column">
            <text-input :value="company.name" @input="name">Name</text-input>
          </div>
          <div class="column">
            <text-input :value="company.vat_number" @input="vatNumber">VAT Number</text-input>
          </div>
          <div class="column">
            <text-input
              :value="company.registration_number"
              @input="registrationNumber"
            >Registration Number</text-input>
          </div>
          <div class="column">
            <data-selector
              :items="industries"
              value-key="id"
              label-key="name"
              :value="company.industry_id"
              @input="updateIndustry">
              Industry
              <template #item="{ item }">
                <span class="whitespace-normal">{{ item.name }}</span>
              </template>
            </data-selector>
          </div>
        </div>

        <physical-address @input="setAddress" :addr="company.address"/>

        <div class="columns">
          <div class="column">
            <submit-button
              class="is-success is-rounded"
              left-icon="save"
              :working="working"
              :disabled="uploading"
              @submit="update">
              Save
            </submit-button>
          </div>
        </div>
      </form>

      <cancel-account />
    </div>
    <div class="column is-3">
      <div class="box is-fullheight">
        <h2 class="box-title">
          Company Logo
        </h2>
        <p class="mb-1">This logo will be used only in PDF reports (Recommended image size 384px x 256px). For further usages, see <a @click="companyBranding" class="has-text-primary">Company Branding</a>.</p>
        <img @click="openImageUploader" :src="company.logo_url" v-if="company.logo_url">
        <p v-else>A logo for your company hasn’t been uploaded yet.</p>
        <div class="is-flex justify-between mt-1">
          <action-button 
            @click="openImageUploader"
            left-icon="upload"
            class="is-primary is-small is-rounded mt-1">
            Upload Logo
          </action-button>
          <action-button 
            v-if="company.logo_url" 
            @click="reset"
            left-icon="redo-alt"
            class="is-danger is-small is-rounded mt-1"
            :working="uploading">
            Reset Logo
          </action-button>
        </div>
        <div class="row pt-2">
          <switch-input
            classes="is-medium"
            @input="toggleBackgroundColor"
            :value="company.white_logo_background">
            Toggle Logo Background {{ backgroundColorText }}
          </switch-input>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { uploadCompanyLogo, companyBranding } from '@/modals'
import CancelAccount from './billing/Cancel.vue'

export default {

  components: {
    CancelAccount
  },

  data: () => ({
    uploading: false,
    loaded: false,
    working: false,
    whiteLogoBackground: false,
  }),

  beforeCreate() {
    this.$store.dispatch('list/loadIndustries')
  },

  mounted() {
    setTimeout(() => {
      this.loaded = true
    }, 100)
  },

  methods: {
    ...mapMutations('company', [
      'name',
      'vatNumber',
      'registrationNumber',
      'setAddress',
      'updateIndustry'
    ]),
    companyBranding,
    openImageUploader() {
      uploadCompanyLogo()
    },
    update() {
      this.working = true
      this.$store.dispatch('company/update').then(() => {
        this.$toast.success('Saved')
        this.working = false
      }).catch(error => {
        this.working = false
        this.$toast.error('Please review your input')
      })
    },
    reset() {
      this.uploading = true
      this.$store.dispatch('company/resetCompanyLogo').then(() => {
        this.uploading = false
        this.$toast.success(this.Convert('Success', 'Your company logo has been reset.'))
      })
    },
    toggleBackgroundColor() {
      this.company.white_logo_background = !this.company.white_logo_background;
      this.$store.commit('company/setWhiteLogoBackground', this.company.white_logo_background);
      this.$store.dispatch('company/update')
        .then(() => {
          this.$toast.success('Success', 'Logo background change.');
        })
        .catch(error => {
          this.$toast.error(this.Convert('Error', 'Failed to update company information.'));
        });
    }
  },

  computed: {
    ...mapGetters('company', [
      'company'
    ]),
    ...mapGetters('list', [
      'industries'
    ]),
    backgroundColorText() {
      return this.company.white_logo_background ? 'Dark' : 'Light';
    },
  }
}
</script>
