<template>
<page icon="user-circle" :title="Naming.Users" v-bind="{ loading }">

  <template #tools>
    <router-link
        v-if="$root.hasAbility('manage-personas', 'App\\User')"
        :to="{ name: 'user-persona-index' }"
        class="mr-1 button is-secondary is-rounded"
    >
      <icon icon="users"/>
      <span>{{ Naming.User}} Personas</span>
    </router-link>
    <router-link
      v-if="$root.hasAbility('import-users', 'App\\User')"
      :to="{ name: 'user-import-index' }"
      class="mr-1 button is-secondary is-rounded"
    >
      <icon icon="file-import"/>
      <span>Import {{ Naming.Users }}</span>
    </router-link>
    <dropdown-wrapper v-if="$root.hasAbility('create-users', 'App\\User')" position="bottom-end">
      <action-button
        slot="trigger"
        left-icon="plus"
        class="is-primary is-rounded">
        New {{ Naming.User }}
      </action-button>
      <dropdown-item @click="addTech">
        <icon icon="user lg" class="is-medium"/>
        <div>
          <p class="has-text-weight-bold">{{ Naming.Technician }}</p>
          <p class="is-size-7">A techinical {{ Naming.User.toLowerCase() }}, typically performing {{ Naming.Inspections.toLowerCase() }} in the field.</p>
        </div>
      </dropdown-item>
      <dropdown-item @click="addAdmin">
        <icon icon="user-shield lg" class="is-medium"/>
        <div>
          <p class="has-text-weight-bold">Admin</p>
          <p class="is-size-7">Office based {{ Naming.User.toLowerCase() }}, typically a manager in control of the system.</p>
        </div>
      </dropdown-item>
    </dropdown-wrapper>
  </template>

  <div class="is-flex align-center justify-between mb-2">
    <div class="tabs is-toggle is-toggle-rounded is-raised is-rounded is-marginless" style="overflow:initial">
      <ul>
        <li :class="{ 'is-active pointer-events-none': filters.role === '' }">
          <a @click="filterBy('role')">Show All</a>
        </li>
        <li :class="{ 'is-active pointer-events-none': filters.role === 'technician' }">
          <a @click="filterBy('role', 'technician')">{{ Naming.Technicians }}</a>
        </li>
        <li :class="{ 'is-active pointer-events-none': filters.role === 'admin' }">
          <a @click="filterBy('role', 'admin')">Admins</a>
        </li>
      </ul>
    </div>
    <div class="flex-grow ml-3 mr-1">
      <text-input
        class="is-marginless"
        classes="is-rounded"
        :debounce="500"
        :placeholder="`Search ${$route.query.technician ? `${Naming.Technicians.toLowerCase()}` : ''}${$route.query.admin ? 'admins' : ''}`"
        :value="filters.search"
        @input="filterBy('search', $event)"
        left-icon="search"
        :label="false">
        <action-button
          v-if="filters.search"
          slot="right"
          class="is-rounded"
          @click="clearFilter('search')">
          <icon icon="times"/>
        </action-button>
      </text-input>
    </div>
  </div>

  <div class="box">
    <div>
      <div class="columns">
        <div class="column">
          <table class="table is-fullwidth is-vcentered is-hoverable">
            <thead class="is-sticky">
              <tr>
                <th>Full Name</th>
                <th>Mobile</th>
                <th>Email</th>
                <th class="is-narrow">Persona</th>
                <th class="is-narrow">{{ Naming.Sites }}</th>
                <th class="is-narrow">{{ Naming.Jobs }}</th>
                <th class="is-narrow">{{ Naming.Inspections }}</th>
                <th class="is-narrow">Active</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="user in users.data" :key="user.id">
                <td>
                  <link-user-overview :is-primary="isPrimaryUser(user)" avatar :user="user"/>
                </td>
                <td>
                  <span v-if="user.profile">{{ user.profile.formatted_mobile }}</span>
                </td>
                <td>{{ user.email }}</td>
                <td>{{ user.persona?.name ?? 'None'}}</td>
                <td class="has-text-centered">{{ user.sites_count }}</td>
                <td class="has-text-centered">{{ user.technical_jobs_count }}</td>
                <td class="has-text-centered">{{ user.inspections_count }}</td>
                <td class="is-narrow has-text-right">
                  <yes-no :value="user.active" yes-icon="check-circle" no-icon="times-circle"/>
                </td>
              </tr>
            </tbody>
          </table>
          <p class="has-text-centered" v-if="!users.data.length">
            No {{ Naming.Users.toLowerCase() }} to display
          </p>
          <pager class="pl" :pageable="users" @nav="goToPage" :context="Naming.User.toLowerCase()"/>
        </div>
      </div>
    </div>
  </div>
</page>
</template>

<script>
import { mapGetters } from 'vuex'
import { createAdmin, createTechnician } from '@/modals'

export default {

  data: () => ({
    loading: true,
    filters: {
      role: '',
      search: ''
    }
  }),

  async created() {
    this.filters = { ...this.filters, ...this.$route.query }
    await this.loadUsers()
    this.loading = false
  },

  methods: {
    loadUsers() {
      this.$scrollToTop()
      return this.$store.dispatch('user/loadUsers', {
        path: '/api' + this.$route.fullPath
      }).then(() => {
        this.loading = false
      })
    },
    goToPage(path) {
      this.loading = true
      this.$router.push(path)
    },
    clearFilter(filter) {
      this.filters[filter] = null
      this.runFilters()
    },
    filterBy(key, value = '') {
      this.filters[key] = value
      this.runFilters()
    },
    runFilters() {
      this.$router.push({
        name: this.$route.name,
        query: this.filters
      })
    },
    addAdmin() {
      createAdmin().then(user => {
        if(user) this.$router.push({
          name: 'user-edit',
          params: { user: user.id }
        })
      })
    },
    addTech() {
      createTechnician().then(user => {
        if(user) this.$router.push({
          name: 'user-edit',
          params: { user: user.id }
        })
      })
    },
    isPrimaryUser(user) {
      return user.id === this.company.primary_user_id
    }
  },

  computed: {
    ...mapGetters('user', [
      'users'
    ]),
    ...mapGetters('company', [
      'company'
    ])
  },

  watch: {
    $route: 'loadUsers',
  }
}
</script>
