<template>
  <article class="grid has-4-columns gap-2">
    <div class="app-logo-preview">
      <img v-if="previewVisible" :src="staged || branding.app_logo_url">
    </div>
    <div class="is-3-columns">
      <p class="mb-1">To set a custom app logo (used in the sidebar on the left), upload an image below. The image you choose must be a transparent <abbr title="Portable Network Graphics File (.png)">PNG</abbr> with a size of exactly <span class="has-text-weight-bold">300px × 82px</span>. Your image will be displayed in such a way that it blends with the colour scheme of the app.</p>
      <div class="is-flex align-end">
        <file-input
          ref="input"
          class="flex-grow is-marginless"
          @select="brandingAppLogo">
          New App Logo
        </file-input>
        <action-button
          v-if="branding.app_logo && !staged"
          v-bind="{ working }"
          @click="reset"
          class="is-danger is-outlined ml-1">
          Reset
        </action-button>
        <action-button
          v-if="staged"
          v-bind="{ working }"
          @click="upload"
          class="is-primary ml-1"
          left-icon="cloud-upload-alt">
          Upload &amp; Set
        </action-button>
      </div>
    </div>
  </article>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { readFile } from '@/utils/file-reader'

export default {

  data: () => ({
    staged: null,
    working: false,
    previewVisible: true,
  }),

  computed: {
    ...mapState('company', ['branding']),
  },

  methods: {
    ...mapMutations('company', [
      'setBrandingAppLogo',
    ]),

    reloadPreview() {
      // This is due to a bug that stretches the image
      // after its src has been replaced.
      // @todo revisit
      this.previewVisible = false
      setTimeout(() => this.previewVisible = true, 50)
    },

    brandingAppLogo(file) {
      if (file.type === 'image/png') {
        this.setBrandingAppLogo(file)
        readFile(file).then(url => {
          let image = new Image()
          let vm = this
          image.onload = function () {
            if (this.height === 82 && this.width === 300) {
              vm.staged = url
              vm.reloadPreview()
            } else {
              vm.$refs.input.files = FileList
              vm.$toast.error('Incorrect Size', 'The size of your logo must be 300px × 82px.')
            }
          }
          image.src = url
        })
      } else {
        this.$refs.input.files = FileList
        this.$toast.error('Incorrect Type', 'Please select a PNG image.')
      }
    },

    upload() {
      this.working = true
      this.$store.dispatch('company/uploadBrandingAppLogo')
        .then(() => {
          this.staged = null
          this.$refs.input.files = FileList
          this.$toast.success(this.Convert('Success', 'App logo for your company has been set.'))
        })
        .finally(() => this.working = false)
    },

    reset() {
      this.working = true
      this.$store.dispatch('company/resetBrandingAppLogo')
        .then(() => {
          this.staged = null
          this.$refs.input.files = FileList
          this.reloadPreview()
          this.$toast.success(this.Convet('Success', 'App logo for your company has been reset.'))
        })
        .finally(() => this.working = false)
    }
  },

}
</script>
