<template>
  <tr>
    <td>
      <router-link
          v-if="$root.hasAbility('update-custom-field-templates', 'App\\Models\\CustomFieldTemplate')"
          class="has-text-info"
          :to="{
          name: 'custom-field-template',
          params: {
            customFieldTemplate: customFieldTemplate.id
          }}">
        {{ customFieldTemplate.name }}
      </router-link>
      <span v-else>{{ customFieldTemplate.name }}</span>
    </td>
    <td>
      {{ templateTypeCleaned }}
    </td>
    <td>
      {{ customFieldTemplate.created_at | date('L') }}
    </td>
    <td>
      <div class="is-flex">
        <span v-if="showingCustomFields===false">
        {{ customFieldTemplate.custom_fields_count }}
        </span>
        <span
            v-if="customFieldTemplate.custom_fields_count"
            class="has-text-grey is-small hover-highlight-grey"
            @click="showingCustomFields = !showingCustomFields">
          <icon :icon="showingCustomFields ? 'ellipsis-h' : 'ellipsis-v'"/>
        </span>
      </div>
      <div v-if="showingCustomFields===true" class="template-item">
        <p @click="editCustomField(custom_field.id)"
           v-for="custom_field in customFieldTemplate.custom_fields"
           :key="custom_field.id">
          {{ custom_field.label }}
        </p>
      </div>
    </td>
    <td class="has-text-right"
        v-if="$root.hasAbility('delete-custom-field-templates', 'App\\Models\\CustomFieldTemplate')">
      <span class="has-text-grey hover-highlight-danger" @click="deleteCustomFieldTemplate">
        <icon icon="trash"/>
      </span>
    </td>
  </tr>
</template>
<script>

import _ from "lodash";
import {mapGetters} from "vuex";

export default {

  name: 'template-item',

  props: {
    customFieldTemplate: {
      type: Object,
      default: () => {{}},
    },
  },

  data: () => ({
    showingCustomFields: false,
  }),

  computed: {
    ...mapGetters('auth', ['user']),
    templateTypeCleaned() {
      const explodedPath = this.customFieldTemplate.template_type.split("\\")
      const index = +explodedPath.length - 1
      return explodedPath[index]
    }
  },

  methods: {
    async deleteCustomFieldTemplate() {
      if (await this.$confirm({
        title: 'Confirm Delete',
        message: `Are you sure you want to delete the <strong>${this.customFieldTemplate.name}</strong> ${this.Convert('custom field')} template?`
      })) {
        this.deleting = true
        this.$store.dispatch('customField/deleteCustomFieldTemplate', this.customFieldTemplate.id).then(() => {
          this.deleting = false
          this.$emit('deleted')
          this.$toast.success('Template Deleted')
        }).catch(() => {
          this.deleting = false
          this.$toast.error('Something went wrong, please try again.')
        })
      }
    },
    editCustomField(id) {
      if (_.filter(this.user.abilities, ability => ability.name === 'update-custom-field-templates')) {
        this.$router.push({
          name: 'template-custom-field-edit',
          params: {
            customFieldTemplate: this.customFieldTemplate.id,
            customField: id
          }
        })
      }
    }
  }
};
</script>
