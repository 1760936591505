<template>
  <div class="is-flex assign-item justify-between">
    <div class="is-flex">
      <checkbox-input
          class="is-marginless"
          @input="toggle"
          classes="is-circle"
          v-model="itemSelected">
      </checkbox-input>
      <div class="has-text-weight-medium">
        {{ site.name }}
      </div>
    </div>
    <p class="ml-2 has-text-cool-blue-text" v-if="site.custom_fields_count > 0">
      {{ site.custom_fields_count }} {{ Naming.CustomField }}<span v-if="site.custom_fields_count > 1">s</span>
    </p>
  </div>
</template>
<script>
export default {
  name: 'assign-item',

  props: {
    site: {
      type: Object,
      required: true
    },
    selected: {
      type: Boolean
    }
  },

  data: () => ({
    itemSelected: false
  }),

  watch: {
    selected() {
      this.itemSelected = this.selected
    }
  },

  methods: {
    toggle() {
      this.$emit('toggled', this.site.id)
    },
  }
}
</script>