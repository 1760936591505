<template>
  <loader v-bind="{ loading }">
    <div class="is-flex justify-between mb-1">
      <h3 class="is-size-4">Edit {{ Naming.CustomField }} Template</h3>
      <div class="buttons has-addons is-rounded is-right">
        <router-link
            class="button is-cool-blue-darker is-rounded"
            :to="{
              name: 'custom-field-templates',
              params: {
                client: this.$route.params.client
              }}">
          <icon icon="angle-double-left"/>
          <span>{{ Naming.CustomField }} Templates</span>
        </router-link>
        <router-link
            v-if="$root.hasAbility('assign-custom-field-template-to-sites', 'App\\Models\\CustomFieldTemplate')"
            class="button is-info is-rounded"
            :to="{
              name: 'template-assign-to-sites',
              params: this.$route.params}">
          <icon icon="list-alt"/>
          <span>Assign Template To {{ Naming.Sites }}</span>
        </router-link>
      </div>
    </div>
    <div class="box">
      <div class="is-flex justify-between align-center mb-1">
        <h3 class="is-size-4 has-text-weight-light">Edit Template</h3>
      </div>
      <div class="columns">
        <div class="column is-6">
          <text-input
              required
              v-model="customFieldTemplate.name">
            Edit Template Name
          </text-input>
        </div>
        <div class="column is-6">
          <data-selector
              classes="is-marginless"
              required
              value-key="value"
              label-key="name"
              v-model="customFieldTemplate.template_type"
              :items="templateTypes"
              prompt-label="Select template type">
            Template Type
          </data-selector>
        </div>
      </div>
      <action-button
          :disabled="templateDataNotCompleted"
          slot="right"
          :working="storing"
          @click="updateTemplate"
          class="is-success"
          left-icon="save">
        Save
      </action-button>
      <hr>
      <div class="is-flex justify-between align-center mt-1 mb-1">
        <h3 class="is-size-4 has-text-weight-light">{{ Naming.CustomFields }}</h3>
        <router-link
            v-if="canCreate"
            class="button is-primary"
            :to="{
                name: 'template-create-custom-field',
                params: $route.params
            }">
          <icon icon="plus-circle"/>
          <span>Add New {{ Naming.CustomField }}</span>
        </router-link>
      </div>
      <custom-fields-draggable-box
          :customFields="customFieldTemplate.custom_fields"
          class=""
          @refreshParent="loadTemplate"/>
    </div>
  </loader>
</template>
<script>
import {mapGetters} from "vuex";

export default {
  name: 'custom-field-template',

  data: () => ({
    loading: true,
    storing: false
  }),

  computed: {
    ...mapGetters('customField', ['customFieldTemplate', 'templateTypes']),
    ...mapGetters('auth', ['user']),
    templateDataNotCompleted() {
      return this.customFieldTemplate.name?.length < 3 || this.customFieldTemplate.template_type?.length < 1
    },
    canCreate() {
      return this.user.abilities?.filter(ability => ability.name === 'create-custom-fields').length > 0
    }
  },

  watch: {
    '$route': 'loadTemplate'
  },

  created() {
    this.loadTemplate()
  },

  methods: {
    async loadTemplate() {
      this.loading = true
      await this.$store.dispatch('customField/loadCustomFieldTemplate', this.$route.params.customFieldTemplate).then(() => {
        this.loading = false
      })
    },
    async updateTemplate() {
      this.storing = true
      await this.$store.dispatch('customField/updateCustomFieldTemplate', this.customFieldTemplate).then(() => {
        this.$toast.success('Template updated successfully.')
        this.$router.push({
          name: 'custom-field-templates',
        })
      }).catch(() => {
        this.$toast.error('Template not updated. Please try again')
        this.storing = false
      })
    },
  }
}
</script>
