<template>
  <loader v-bind="{ loading }">
    <div class="box">
      <form>
        <h3 class="title is-4 mb-1">New {{ Naming.CustomField }} Template</h3>
        <div class="columns">
          <div class="column is-6">
            <text-input
                required
                v-model="customFieldTemplate.name">
              Template Name
            </text-input>
          </div>
          <div class="column is-6">
            <data-selector
                classes="is-marginless"
                required
                value-key="value"
                label-key="name"
                v-model="customFieldTemplate.template_type"
                :items="templateTypes"
                prompt-label="Select template type">
              Template Type
            </data-selector>
          </div>
        </div>
        <submit-button
            :disabled="templateDataNotCompleted"
            slot="right"
            :working="storing"
            @submit="storeTemplate"
            class="is-success"
            left-icon="check">
          Create Template
        </submit-button>
      </form>
    </div>
  </loader>
</template>

<script>

import {mapGetters} from "vuex";

export default {

  name: 'create-custom-field-template',

  data: () => ({
    storing: false,
    loading: true,
    customFieldTemplate: {
      name: '',
      template_type: ''
    },
  }),

  computed: {
    ...mapGetters('customField', ['templateTypes']),
    templateDataNotCompleted() {
      return this.customFieldTemplate.name?.length < 3 || this.customFieldTemplate.template_type?.length < 1
    }
  },

  created() {
    this.loading = false
  },

  methods: {
    storeTemplate() {
      this.storing = true
      this.$store.dispatch('customField/storeCustomFieldTemplate', this.customFieldTemplate).then((data) => {
        this.$router.push(
            {
              name: 'custom-field-template',
              params: {
                customFieldTemplate: data.id
              }
            })
        this.$toast.success('Template created')
      }).catch(() => {
        this.storing = false
        this.$toast.error('Something went wrong, please try again.')
      })
    },
  },

}
</script>
