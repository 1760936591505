<template>
  <div class="columns" v-if="loaded">
    <div class="column">
      <form class="box">
        <div class="columns">
          <div class="column">
            <text-input value="All Clients" disabled>Field Label</text-input>
          </div>
          <div class="column">
            <text-input :value="conventions['ALL_CLIENTS']" @input="update('ALL_CLIENTS', $event)">All Clients</text-input>
          </div>
        </div>

        <div class="columns">
          <div class="column">
            <text-input value="All Jobs" disabled>Field Label</text-input>
          </div>
          <div class="column">
            <text-input :value="conventions['ALL_JOBS']" @input="update('ALL_JOBS', $event)">All Jobs</text-input>
          </div>
        </div>

        <div class="columns">
          <div class="column">
            <text-input value="Asset" disabled>Field Label</text-input>
          </div>
          <div class="column">
            <text-input :value="conventions['ASSET']" @input="update('ASSET', $event)">Asset</text-input>
          </div>
        </div>

        <div class="columns">
          <div class="column">
            <text-input value="Assets" disabled>Field Label</text-input>
          </div>
          <div class="column">
            <text-input :value="conventions['ASSETS']" @input="update('ASSETS', $event)">Assets</text-input>
          </div>
        </div>

        <div class="columns">
          <div class="column">
            <text-input value="Asset Dashboard" disabled>Field Label</text-input>
          </div>
          <div class="column">
            <text-input :value="conventions['ASSET_DASHBOARD']" @input="update('ASSET_DASHBOARD', $event)">Asset Dashboard</text-input>
          </div>
        </div>

        <div class="columns">
          <div class="column">
            <text-input value="Asset Group" disabled>Field Label</text-input>
          </div>
          <div class="column">
            <text-input :value="conventions['ASSET_GROUP']" @input="update('ASSET_GROUP', $event)">Asset Group</text-input>
          </div>
        </div>

        <div class="columns">
          <div class="column">
            <text-input value="Asset Groups" disabled>Field Label</text-input>
          </div>
          <div class="column">
            <text-input :value="conventions['ASSET_GROUPS']" @input="update('ASSET_GROUPS', $event)">Asset Groups</text-input>
          </div>
        </div>

        <div class="columns">
          <div class="column">
            <text-input value="Asset Type" disabled>Field Label</text-input>
          </div>
          <div class="column">
            <text-input :value="conventions['ASSET_TYPE']" @input="update('ASSET_TYPE', $event)">Asset Type</text-input>
          </div>
        </div>

        <div class="columns">
          <div class="column">
            <text-input value="Asset Types" disabled>Field Label</text-input>
          </div>
          <div class="column">
            <text-input :value="conventions['ASSET_TYPES']" @input="update('ASSET_TYPES', $event)">Asset Types</text-input>
          </div>
        </div>

        <div class="columns">
          <div class="column">
            <text-input value="Calendar" disabled>Field Label</text-input>
          </div>
          <div class="column">
            <text-input :value="conventions['CALENDAR']" @input="update('CALENDAR', $event)">Calendar</text-input>
          </div>
        </div>

        <div class="columns">
          <div class="column">
            <text-input value="Check" disabled>Field Label</text-input>
          </div>
          <div class="column">
            <text-input :value="conventions['CHECK']" @input="update('CHECK', $event)">Check</text-input>
          </div>
        </div>

        <div class="columns">
          <div class="column">
            <text-input value="Checks" disabled>Field Label</text-input>
          </div>
          <div class="column">
            <text-input :value="conventions['CHECKS']" @input="update('CHECKS', $event)">Checks</text-input>
          </div>
        </div>

        <div class="columns">
          <div class="column">
            <text-input value="Checklist" disabled>Field Label</text-input>
          </div>
          <div class="column">
            <text-input :value="conventions['CHECKLIST']" @input="update('CHECKLIST', $event)">Checklist</text-input>
          </div>
        </div>

        <div class="columns">
          <div class="column">
            <text-input value="Checklists" disabled>Field Label</text-input>
          </div>
          <div class="column">
            <text-input :value="conventions['CHECKLISTS']" @input="update('CHECKLISTS', $event)">Checklists</text-input>
          </div>
        </div>

        <div class="columns">
          <div class="column">
            <text-input value="Client" disabled>Field Label</text-input>
          </div>
          <div class="column">
            <text-input :value="conventions['CLIENT']" @input="update('CLIENT', $event)">Client</text-input>
          </div>
        </div>

        <div class="columns">
          <div class="column">
            <text-input value="Clients" disabled>Field Label</text-input>
          </div>
          <div class="column">
            <text-input :value="conventions['CLIENTS']" @input="update('CLIENTS', $event)">Clients</text-input>
          </div>
        </div>

        <div class="columns">
          <div class="column">
            <text-input value="Client Sites" disabled>Field Label</text-input>
          </div>
          <div class="column">
            <text-input :value="conventions['CLIENT_SITES']" @input="update('CLIENT_SITES', $event)">Client Sites</text-input>
          </div>
        </div>

        <div class="columns">
          <div class="column">
            <text-input value="Company" disabled>Field Label</text-input>
          </div>
          <div class="column">
            <text-input :value="conventions['COMPANY']" @input="update('COMPANY', $event)">Company</text-input>
          </div>
        </div>

        <div class="columns">
          <div class="column">
            <text-input value="Companies" disabled>Field Label</text-input>
          </div>
          <div class="column">
            <text-input :value="conventions['COMPANIES']" @input="update('COMPANIES', $event)">Companies</text-input>
          </div>
        </div>

        <div class="columns">
          <div class="column">
            <text-input value="Custom Field" disabled>Field Label</text-input>
          </div>
          <div class="column">
            <text-input :value="conventions['CUSTOM_FIELD']" @input="update('CUSTOM_FIELD', $event)">Custom Field</text-input>
          </div>
        </div>

        <div class="columns">
          <div class="column">
            <text-input value="Custom Fields" disabled>Field Label</text-input>
          </div>
          <div class="column">
            <text-input :value="conventions['CUSTOM_FIELDS']" @input="update('CUSTOM_FIELDS', $event)">Custom Fields</text-input>
          </div>
        </div>

        <div class="columns">
          <div class="column">
            <text-input value="Inspection" disabled>Field Label</text-input>
          </div>
          <div class="column">
            <text-input :value="conventions['INSPECTION']" @input="update('INSPECTION', $event)">Inspection</text-input>
          </div>
        </div>

        <div class="columns">
          <div class="column">
            <text-input value="Inspections" disabled>Field Label</text-input>
          </div>
          <div class="column">
            <text-input :value="conventions['INSPECTIONS']" @input="update('INSPECTIONS', $event)">Inspections</text-input>
          </div>
        </div>

        <div class="columns">
          <div class="column">
            <text-input value="Job" disabled>Field Label</text-input>
          </div>
          <div class="column">
            <text-input :value="conventions['JOB']" @input="update('JOB', $event)">Job</text-input>
          </div>
        </div>

        <div class="columns">
          <div class="column">
            <text-input value="Jobs" disabled>Field Label</text-input>
          </div>
          <div class="column">
            <text-input :value="conventions['JOBS']" @input="update('JOBS', $event)">Jobs</text-input>
          </div>
        </div>

        <div class="columns">
          <div class="column">
            <text-input value="Job Type" disabled>Field Label</text-input>
          </div>
          <div class="column">
            <text-input :value="conventions['JOB_TYPE']" @input="update('JOB_TYPE', $event)">Job Type</text-input>
          </div>
        </div>

        <div class="columns">
          <div class="column">
            <text-input value="Job Types" disabled>Field Label</text-input>
          </div>
          <div class="column">
            <text-input :value="conventions['JOB_TYPES']" @input="update('JOB_TYPES', $event)">Job Types</text-input>
          </div>
        </div>

        <div class="columns">
          <div class="column">
            <text-input value="Overview" disabled>Field Label</text-input>
          </div>
          <div class="column">
            <text-input :value="conventions['OVERVIEW']" @input="update('OVERVIEW', $event)">Overview</text-input>
          </div>
        </div>

        <div class="columns">
          <div class="column">
            <text-input value="Recurring Job" disabled>Field Label</text-input>
          </div>
          <div class="column">
            <text-input :value="conventions['RECURRING_JOB']" @input="update('RECURRING_JOB', $event)">Recurring Job</text-input>
          </div>
        </div>

        <div class="columns">
          <div class="column">
            <text-input value="Recurring Jobs" disabled>Field Label</text-input>
          </div>
          <div class="column">
            <text-input :value="conventions['RECURRING_JOBS']" @input="update('RECURRING_JOBS', $event)">Recurring Jobs</text-input>
          </div>
        </div>

        <div class="columns">
          <div class="column">
            <text-input value="Site" disabled>Field Label</text-input>
          </div>
          <div class="column">
            <text-input :value="conventions['SITE']" @input="update('SITE', $event)">Site</text-input>
          </div>
        </div>

        <div class="columns">
          <div class="column">
            <text-input value="Sites" disabled>Field Label</text-input>
          </div>
          <div class="column">
            <text-input :value="conventions['SITES']" @input="update('SITES', $event)">Sites</text-input>
          </div>
        </div>

        <div class="columns">
          <div class="column">
            <text-input value="Technician" disabled>Field Label</text-input>
          </div>
          <div class="column">
            <text-input :value="conventions['TECHNICIAN']" @input="update('TECHNICIAN', $event)">Technician</text-input>
          </div>
        </div>

        <div class="columns">
          <div class="column">
            <text-input value="Technicians" disabled>Field Label</text-input>
          </div>
          <div class="column">
            <text-input :value="conventions['TECHNICIANS']" @input="update('TECHNICIANS', $event)">Technicians</text-input>
          </div>
        </div>

        <div class="columns">
          <div class="column">
            <text-input value="Translations" disabled>Field Label</text-input>
          </div>
          <div class="column">
            <text-input :value="conventions['TRANSLATIONS']" @input="update('TRANSLATIONS', $event)">Translations</text-input>
          </div>
        </div>

        <div class="columns">
          <div class="column">
            <text-input value="User" disabled>Field Label</text-input>
          </div>
          <div class="column">
            <text-input :value="conventions['USER']" @input="update('USER', $event)">User</text-input>
          </div>
        </div>

        <div class="columns">
          <div class="column">
            <text-input value="Users" disabled>Field Label</text-input>
          </div>
          <div class="column">
            <text-input :value="conventions['USERS']" @input="update('USERS', $event)">Users</text-input>
          </div>
        </div>

        <div class="columns">
          <div class="column">
            <text-input value="Zone" disabled>Field Label</text-input>
          </div>
          <div class="column">
            <text-input :value="conventions['ZONE']" @input="update('ZONE', $event)">Zone</text-input>
          </div>
        </div>

        <div class="columns">
          <div class="column">
            <text-input value="Zones" disabled>Field Label</text-input>
          </div>
          <div class="column">
            <text-input :value="conventions['ZONES']" @input="update('ZONES', $event)">Zones</text-input>
          </div>
        </div>

        <div class="columns">
          <div class="column">
            <submit-button
                class="is-success is-rounded"
                left-icon="save"
                :working="working"
                :disabled="uploading"
                @submit="saveNamingConventions">
              Save
            </submit-button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { uploadCompanyLogo, companyBranding } from '@/modals'
import CancelAccount from './billing/Cancel.vue'

export default {

  data: () => ({
    uploading: false,
    loaded: false,
    working: false,
    saved: true,
    conventions: [],
  }),

  mounted() {
    setTimeout(() => {
      this.loaded = true
    }, 100)

    this.copyNamingConventions()
  },

  methods: {
    ...mapMutations('namingConventions', [
        'setNamingConventions',
        'updateNamingConvention'
    ]),
    copyNamingConventions() {

      this.conventions = JSON.parse(JSON.stringify(this.names))
    },
    update(label, value) {
      this.saved = false
      // this.updateNamingConvention({label: label, value: value})
      this.conventions[label] = value
    },
    saveNamingConventions() {
      this.working = true
      this.$store.dispatch('namingConventions/postNamingConventions', this.conventions).then(() => {
        this.$toast.success('Saved')
        this.working = false
      }).catch(error => {
        this.working = false
        this.$toast.error('Please review your input')
      })
    },
  },

  computed: {
    ...mapGetters('namingConventions', [
      'names'
    ]),
  }
}
</script>
