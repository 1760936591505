<template>
  <loader v-bind="{ loading }">
    <div class="is-flex justify-between align-center mb-1">
      <h3 class="is-size-4">Predefined Answer List</h3>
      <action-button
          :working="working"
          class="is-info is-rounded"
          @click="savePredefinedAnswers"
          left-icon="angle-double-left">
        {{ Naming.CustomField }}
      </action-button>
    </div>
    <div class="box">
      <predefined-answer-create
          @answerSaved="updateAnswerList"/>
      <div v-if="answers.length > 0" class="mb-1">
        <div class="is-size-5 mb-2 mt-1">Predefined Answer List</div>
        <predefined-answer-item
            v-for="answer in answers"
            :key="answer.id"
            :answer="answer"
            @answerChanged="updateAnswerList"/>
      </div>
      <div v-else class="mb-1">
        <notification class="is-cool-blue has-text-centered p-2 is-marginless">
          <p class="has-text-weight-bold">No Predefined Answers Created</p>
          <p>You haven’t created any predefined answers yet.</p>
        </notification>
      </div>
      <div class="is-flex justify-end">
        <action-button
            :working="working"
            class="is-success is-rounded"
            @click="savePredefinedAnswers"
            left-icon="save">
          Save Predefined Answer List
        </action-button>
      </div>
    </div>
  </loader>
</template>
<script>
import {mapGetters} from "vuex";

export default {
  name: 'predefined-answers-index',

  data: () => ({
    loading: true,
    working: false,
    answers: [],
  }),

  watch: {
    customField() {
      this.answers = this.customField.predefined_answers
    }
  },

  computed: {
    ...mapGetters('customField', [
      'customField'
    ])
  },

  created() {
    this.loadCustomField()
  },

  methods: {
    savePredefinedAnswers() {
      this.$router.push({
        name: 'template-custom-field-edit',
        params: {
          customFieldTemplate: this.customField.custom_fieldable_id,
          customField: this.customField.id
        }
      })
    },
    async loadCustomField() {
      await this.$store.dispatch('customField/loadCustomField', this.$route.params.customField).then(() => {
        this.loading = false
      })
    },
    updateAnswerList() {
      this.loadCustomField()
    },
  }
}
</script>