<template>
<page icon="sync" title="Payment Status">
  <div class="box ptb-3">
    <div v-if="awaitingStatus">
      <h1 class="is-size-5 has-text-weight-light has-text-centered">Please wait while retrieve your card status...</h1>
    </div>
    <div v-else>
      <div v-if="payment.status !== 'successful'">
        <h3 class="title is-4 has-text-danger">
          <icon icon="exclamation-triangle" class="mr"/>
          <span>Error: {{ payment.result_code }}</span>
        </h3>
        <p>
          {{ payment.result_description }}
        </p>
        <p class="mt-1">
          There was an error processing your request, please contact support.
        </p>
        <router-link class="button is-info" :to="{ name: 'support.tickets' }">Request Support</router-link>
      </div>
    </div>
  </div>
</page>  
</template>

<script>
import { company as backend } from '@/api'

export default {

  data: () => ({
    payment: {
      result: {}
    },
    awaitingStatus: true
  }),

  created() {
    // if(!this.$route.query.id || !this.$route.query.resourcePath) {
    //   this.$router.push({ name: 'dashboard' })
    //   return
    // }
    this.verify()
  },

  methods: {
    verify() {
      backend.verifyCard({
        checkout_id: this.$route.query.id
      }, response => {
        this.$toast.success('Card added succesfully')
        this.$router.push({
            name: 'billing-card'
          })
      }, error => {
        this.$toast.error(error.response.data)
      })
    }
  }

}
</script>