<template>
  <loader v-bind="{ loading }">
    <div class="box">
      <h3 class="title is-4 level-item">{{ Naming.CustomFields}}</h3>
      <div class="columns">
        <div class="column is-6">
          <data-selector
              classes="is-marginless"
              value-key="value"
              label-key="name"
              v-model="filters.templateType"
              :items="templateTypes"
              prompt-label="Filter by template type"
              @input="filter">
            <action-button
                v-if="filters.templateType"
                slot="right"
                @click="clearTypeFilter">
              <icon icon="times"/>
            </action-button>
          </data-selector>
        </div>
        <div class="column is-6">
          <text-input
              v-model="filters.search"
              :placeholder="`Search ${Naming.CustomField.toLowerCase()} Templates…`">
            <div slot="right">
              <action-button
                  v-if="filters.search"
                  slot="right"
                  @click="clearSearch()"
                  class="">
                <icon icon="times"/>
              </action-button>
              <action-button @click="filter" left-icon="search" class="is-cool-blue">Search</action-button>
            </div>
          </text-input>
        </div>
      </div>
      <table v-if="hasTemplates" class="table is-fullwidth">
        <thead>
        <tr>
          <th>Name</th>
          <th>Template Type</th>
          <th>Created</th>
          <th>{{ Naming.CustomFields }}</th>
          <th
              class="is-narrow"
              v-if="$root.hasAbility('delete-custom-field-templates', 'App\\Models\\CustomFieldTemplate')">
            Delete Template
          </th>
        </tr>
        </thead>
        <tbody>
        <template-item
            v-for="customFieldTemplate in customFieldTemplates.data"
            :key="customFieldTemplate.id"
            :customFieldTemplate="customFieldTemplate"
            @deleted="loadCustomFieldTemplates"/>
        </tbody>
      </table>
      <div class="mt-1 mb-2" v-else>
        <div class="has-text-centered has-text-grey has-background-cool-blue p-2">
          There are no {{ Naming.CustomField }} templates.
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <pager :pageable="customFieldTemplates" @nav="goToPage" context="Template"/>
        </div>
        <div
            class="column is-narrow"
            :data-tooltip="'You do not have the necessary permission to view deleted templates'"
            :class="{ 'has-tooltip-left has-tooltip-multiline' : !canViewDeletedTemplates}">
          <action-button
              @click="goToDeletedTemplates"
              class="is-small is-cool-blue-darker"
              :disabled="!canViewDeletedTemplates"
              left-icon="trash">
            Deleted Templates
          </action-button>
        </div>
      </div>
    </div>
  </loader>
</template>

<script>
import {mapGetters} from 'vuex'
import _ from "lodash";

export default {

  name: 'template-index',

  data: () => ({
    loading: true,
    filters: {
      search: '',
      templateType: ''
    }
  }),

  watch: {
    '$route': 'loadCustomFieldTemplates'
  },

  computed: {
    ...mapGetters('customField', [
      'customField',
      'customFields',
      'customFieldTemplate',
      'customFieldTemplates',
      'templateTypes'
    ]),
    ...mapGetters('auth', ['user']),
    canViewDeletedTemplates() {
      return _.filter(this.user.abilities, ability => ability.name === 'view-trashed-custom-field-templates').length > 0
    },
    hasTemplates() {
      return this.customFieldTemplates.data?.length > 0
    }
  },

  created() {
    this.loadCustomFieldTemplates()
  },

  methods: {
    async loadCustomFieldTemplates() {
      this.loading = true
      await this.$store.dispatch('customField/loadCustomFieldTemplates', {
            path: 'api' + this.$route.fullPath,
            params: this.filters
          }
      ).then(() => {
        this.loading = false
      })
    },
    filter() {
      this.$scrollToTop()
      this.loadCustomFieldTemplates()
    },
    clearSearch() {
      this.filters.search = null
      this.loadCustomFieldTemplates()
    },
    clearTypeFilter() {
      this.filters.templateType = null
      this.loadCustomFieldTemplates()
    },
    goToPage(path) {
      this.$scrollToTop()
      this.$router.push(path)
    },
    goToDeletedTemplates() {
      this.$router.push({
        name: 'deleted-templates'
      })
    }
  },
}
</script>
