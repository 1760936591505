<template>
  <loader v-bind="{ loading } ">
    <div class="box">
      <div class="is-flex justify-between mb-1">
        <div>
          <h3 class="is-size-4">Assign Template To {{ Naming.Sites }}</h3>
          <h4 class="is-size-6 has-text-grey">
            Select which {{ Naming.Sites.toLowerCase() }} the <strong>{{ customFieldTemplate.name }}</strong> template should be assigned to.
          </h4>
        </div>
      </div>
      <hr>
      <div v-if="showAssignedSites === false">
        <div class="columns is-flex align-center">
          <div class="column is-6">
            <checkbox-input
                classes="is-primary"
                class=""
                :inner-label="`Only show ${Naming.Sites.toLowerCase()} without ${Naming.CustomFields.toLowerCase()}.`"
                :value="filteringWithoutCustomFields"
                @input="filteringWithoutCustomFields = !filteringWithoutCustomFields">
            </checkbox-input>
          </div>
          <div class="column is-6 is-flex justify-end">
            <action-button
                class="is-success is-rounded mr-1"
                left-icon="check-circle"
                @click="selectAll">
              Select All
            </action-button>
            <action-button
                class="is-danger is-rounded"
                left-icon="times-circle"
                @click="deselectAll">
              Deselect All
            </action-button>
          </div>
        </div>
        <div class="grid has-2-columns gap-1 flex-grow mt-1">
          <assign-item
              v-for="site in filteredSiteList"
              :site="site"
              :selected="selected"
              :key="site.id"
              @toggled="toggleAddToSitesList"/>
        </div>
        <div class="mt-2">
          <action-button
              :disabled="!$root.hasAbility('assign-custom-field-template-to-sites', 'App\\Models\\CustomFieldTemplate')"
              @click="assignTemplates"
              class="is-success is-rounded"
              left-icon="list-alt">
            Assign Template To {{ Naming.Sites }}
          </action-button>
        </div>
      </div>
      <div v-else>
        <p class="has-text-grey">
          The {{ customFieldTemplate.name }} Template has been assigned to the following
          {{ Naming.Sites }}:
        </p>
        <div v-for="site in assignedSites" :key="site.id" class="has-text-weight-medium mtb-1 is-flex">
          <icon icon="check-circle" class="has-text-success"/>
          <span class="ml-1">{{ site.name }}</span>
        </div>
        <router-link
            class="button is-info is-rounded"
            :to="{
                name: 'custom-field-template',
                params: this.$route.params}">
          <icon icon="angle-double-left"/>
          <span>{{ customFieldTemplate.name }} Template</span>
        </router-link>
      </div>
    </div>
  </loader>
</template>
<script>
import {mapGetters} from "vuex";
import AssignItem from "@/views/custom-field-templates/partials/AssignItem";
import _ from "lodash";

export default {
  name: 'template-assign-to-sites',

  components: {AssignItem},

  data: () => ({
    working: false,
    loading: true,
    selectedSiteIds: [],
    selected: false,
    showAssignedSites: false,
    assignedSites: [],
    filteringWithoutCustomFields: false
  }),

  computed: {
    ...mapGetters('site', ['siteList']),
    ...mapGetters('customField', ['customFieldTemplate']),
    filteredSiteList() {
      if (this.filteringWithoutCustomFields) {
        return _.filter(this.siteList, site => site.custom_fields_count < 1)
      }
      return this.siteList
    }
  },

  async created() {
    await Promise.all([
      this.$store.dispatch('site/loadSiteListByCompany'),
      this.$store.dispatch('customField/loadCustomFieldTemplate', this.$route.params.customFieldTemplate)]).then(() => {
      this.loading = false
    })
  },

  methods: {
    async assignTemplates() {
      this.loading = true
      const payload = {
        templateId: this.customFieldTemplate.id,
        siteIds: this.selectedSiteIds
      }
      await this.$store.dispatch('customField/assignTemplateToSites', payload).then((data) => {
        this.$toast.success(this.Convert('Template assigned to sites.'))
        this.assignedSites = data
        this.showAssignedSites = true
      }).finally(() => {
        this.loading = false
      }).catch(error => failure(error))
    },
    toggleAddToSitesList(siteId) {
      const index = +this.selectedSiteIds.indexOf(siteId)
      if (index === -1) {
        this.selectedSiteIds.push(siteId)
      } else {
        this.selectedSiteIds = this.selectedSiteIds.splice(index, 1)
      }
    },
    selectAll() {
      this.selectedSiteIds = []
      this.selectedSiteIds = _.map(this.siteList, 'id')
      this.selected = true
    },
    deselectAll() {
      this.selectedSiteIds = []
      this.selected = false
    }
  }
}
</script>